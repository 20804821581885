export default function divideByGroupsOf(arr, groupsOf) {
  const groups = [];

  for (let i = 0, n = arr.length / groupsOf; i < n; i += 1) {
    const slice = arr.slice(groupsOf * i, groupsOf * (i + 1));
    groups.push({
      elements: slice,
      id: slice.map(c => c.id).join('-'),
    });
  }

  return groups;
}
